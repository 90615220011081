<template>
  <!-- eslint-disable -->
  <Form
    :action="action"
    :id="id"
    :dataListTagihanVal="dataListTagihan"
    :tambahanBiayaVal="tambahanBiaya"
    :potonganHargaVal="potonganHarga"
    :pajakVal="pajak"
    :pajakPersenVal="pajakPersen"
    :tambahanPersenVal="tambahanPersen"
    :potonganPersenVal="potonganPersen"
    :statusKonfirmasiVal="statusKonfirmasi"
    :bankVal="bank"
    :adminKeuanganVal="adminKeuangan"
    :penggunaVal="pengguna"
    :kelompokVal="kelompok"
    :dataBankVal="dataBank"
    :dataAdminKeuanganVal="dataAdminKeuangan"
  />
</template>

<script>
import Form from "./Form.vue";
import { ref } from "@vue/composition-api";
import { convertIntToPersen } from "@/libs/helper";
import ApiPengguna from "@/composables/mjmn/pengguna";
import ApiBank from "@/composables/mst/tagihan/bank";

export default {
  setup(props, context) {
    const g = context.root;
    const apiPengguna = new ApiPengguna(g);
    const apiBank = new ApiBank(g);

    const $http = g.$http;

    let tambahanBiaya = ref("");
    let tambahanPersen = ref("");
    let kelompok = ref(null);
    let pengguna = ref(null);
    let potonganHarga = ref("");
    let potonganPersen = ref("");
    let pajak = ref("");
    let pajakPersen = ref("");
    let bank = ref(null);
    let adminKeuangan = ref(null);

    let statusKonfirmasi = ref("");

    let dataListTagihan = ref([]);
    let dataBank = ref([]);
    let dataAdminKeuangan = ref([]);

    const getPenggunaKeuangan = async (id = "") => {
      const response = await apiPengguna.getPenggunaKeuangan(id);
      dataAdminKeuangan.value = [
        { text: "-- Pilih Opsi --", value: null },
        ...response.data.data.dataPengguna,
      ];
      adminKeuangan.value = response.data.data.pengguna;
    };

    const getBank = async (id = "") => {
      const response = await apiBank.getOpsiBank(id);
      const { banks, idBank } = response.data.data;
      dataBank.value = [{ text: "-- Pilih Opsi --", value: null }, ...banks];
      bank.value = idBank;
    };

    const getData = () => {
      $http({
        url: "/tagihan/ubah/" + props.id,
        headers: {
          Authorization: "Bearer " + g.$cookies.get("token"),
        },
      })
        .then((res) => {
          const { detailTagihans, tagihan } = res.data.data;
          let {
            pajak: pajakVal,
            potonganHarga: potonganHargaVal,
            statusKonfirmasi: statusKonfirmasiVal,
            status: statusVal,
            subtotal: subtotalVal,
            tambahanBiaya: tambahanBiayaVal,
            total: totalVal,
            idMstBank,
            idAdminKeuangan,
            kelompok: kelompokVal, 
            idUserTujuan
          } = tagihan;
          // const { dataPesananVal, dataBankVal } = dataTagihan;
          dataListTagihan.value = detailTagihans;
          statusKonfirmasi.value = statusKonfirmasiVal;
          kelompok.value = kelompokVal
          pengguna.value = idUserTujuan

          let tambahanPersenVal, potonganPersenVal, pajakPersenVal;
          if (tambahanBiayaVal) {
            tambahanPersenVal = convertIntToPersen(
              tambahanBiayaVal,
              subtotalVal
            );
            tambahanBiayaVal = tambahanBiayaVal
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          } else {
            tambahanBiayaVal = "";
            tambahanPersenVal = "";
          }

          if (potonganHargaVal) {
            potonganPersenVal = convertIntToPersen(
              potonganHargaVal,
              subtotalVal
            );
            potonganHargaVal = potonganHargaVal
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          } else {
            potonganHargaVal = "";
            potonganPersenVal = "";
          }

          if (pajakVal) {
            pajakPersenVal = convertIntToPersen(pajakVal, subtotalVal);
            pajakVal = pajakVal
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          } else {
            pajakVal = "";
            pajakPersenVal = "";
          }

          tambahanBiaya.value = tambahanBiayaVal;
          potonganHarga.value = potonganHargaVal;
          pajak.value = pajakVal;

          tambahanPersen.value = tambahanPersenVal;
          potonganPersen.value = potonganPersenVal;
          pajakPersen.value = pajakPersenVal;

          getBank(idMstBank);
          getPenggunaKeuangan(idAdminKeuangan);

          // dataBank.value = dataBankVal;
        })
        .catch((err) => {
          let showError = err.response
            ? err.response.data
            : err.request
            ? err.request
            : "Error" + err.message;

          g.$bvToast.toast(showError || "Something Wrong", {
            title: `Error Load Data`,
            variant: "danger",
            solid: true,
          });
        });
    };

    getData();

    return {
      tambahanBiaya,
      tambahanPersen,
      potonganHarga,
      potonganPersen,
      pajak,
      pajakPersen,
      statusKonfirmasi,
      bank,
      kelompok,
      pengguna,
      adminKeuangan,
      dataListTagihan,
      dataBank,
      dataAdminKeuangan,
    };
  },
  components: {
    Form,
  },
  props: ["id", "action"],
};
</script>